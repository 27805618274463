import { get_owner_type } from "~/plugins/common-fns";
import { getAddressAsLongString } from "~/helpers/address-helpers.js";
import { utils, write, writeFile } from "xlsx";
import { i18n } from "~/plugins/translator";
import moment from "moment";
import { calculateWeightedArea, areaWeights } from "~/helpers/area-calculation-helpers.js";
import { getLatestTransaction } from "~/helpers/transaction-helpers";
import { getConstructionYears, getPropertyAddress } from "~/helpers/property-helpers.js";
import { getCompanyKeys, getEnergyLabelKeys, getPlanKeys, getSoilConKeys, getMortgatesKeys } from "~/helpers/keys-helpers.js";

export const writeSheetsToXSLX = function (sheets) {
  const workbook = utils.book_new();
  sheets.forEach((sheet) => {
    utils.book_append_sheet(workbook, sheet.sheetdata, sheet.sheetname);
  });
  const options = { bookType: "xlsx", bookSST: false, type: "base64" };
  return write(workbook, options);
};

export const writeSheetsToXSLXAndSave = function (sheets, filename) {
  const workbook = utils.book_new();
  sheets.forEach((sheet) => {
    utils.book_append_sheet(workbook, sheet.sheetdata, sheet.sheetname);
  });
  writeFile(workbook, `${filename}.xlsx`);
};

export const writeAoaToXSLXAndSave = function (sheetdata, sheetname, filename) {
  const worksheet = utils.aoa_to_sheet(sheetdata);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, sheetname);
  writeFile(workbook, `${filename}.xlsx`);
};

export const map_to_xlsx_sheet = function (keys, payload, sheetname) {
  let rows = [];
  payload
    ?.filter((p) => p != null)
    ?.forEach((x) => {
      let obj = {};
      keys.forEach((y) => {
        obj[y.name] = x[y.value];
      });
      rows.push(obj);
    });
  return { sheetdata: utils.json_to_sheet(rows), sheetname: sheetname };
};

export const createPlotsSheet = function (payload, language) {
  const keys = [
    { name: i18n.t("PLOT_TABLE_CADASTRE", language), value: "matEjerlav" },
    { name: i18n.t("PLOT_TABLE_TOTAL_AREA", language), value: "registeredArea" },
    { name: i18n.t("PLOT_TABLE_PRESERVED_FOREST", language), value: "preservedForestArea" },
    { name: i18n.t("PLOT_TABLE_NO_OF_BUILDINGS", language), value: "noOfBuildings" },
    { name: i18n.t("PLOT_TABLE_NO_OF_UNITS", language), value: "noOfUnits" },
    // { name: i18n.t("PLOT_TABLE_NO_OF_CONDOS", language), value: "noOfCondos" },
    { name: i18n.t("PLOT_TABLE_NO_OF_STATUS", language), value: "status" },
    { name: i18n.t("PLOT_TABLE_NO_OF_TECH", language), value: "noOfTechnicalFacilities" },
  ];
  return map_to_xlsx_sheet(keys, payload, i18n.t("PLOT_TABLE_DOWNLOAD_NAME"));
};

export const createBuildingsSheet = function (payload, language) {
  const keys = [
    { name: i18n.t("BUILDING_TABLE_NO", language), value: "buildingNumber" },
    { name: i18n.t("BUILDING_TABLE_ADDRESS", language), value: "addressText" },
    { name: i18n.t("BUILDING_TABLE_LOCATED", language), value: "matEjerlav" },
    { name: i18n.t("BUILDING_TABLE_USAGE", language), value: "usageText" },
    { name: i18n.t("PLOT_TABLE_NO_OF_STATUS", language), value: "statusText" },
    { name: i18n.t("BUILDING_TABLE_CONSTRUCT", language), value: "constructionYear" },
    { name: i18n.t("BUILDING_TABLE_PRESERVED", language), value: "conservationText" },
    { name: i18n.t("BUILDING_TABLE_NO_OF_FLOORS", language), value: "noOfFloors" },
    { name: i18n.t("BUILDING_TABLE_ENERGY_LABEL", language), value: "energyLabel" },
    { name: i18n.t("BUILDING_TABLE_HEATING", language), value: "heatingInstallationText" },
    { name: i18n.t("BUILDING_TABLE_WATER_SUPPLY", language), value: "waterSupplyText" },
    { name: i18n.t("BUILDING_TABLE_DRAINAGE", language), value: "drainageConditionText" },
    { name: i18n.t("BUILDING_TABLE_ROOF", language), value: "roofingMaterialText" },
    { name: i18n.t("BUILDING_TABLE_WALLS", language), value: "outerWallMaterialText" },
    { name: i18n.t("BUILDING_TABLE_BUILT_AREA", language), value: "builtArea" },
    { name: i18n.t("BUILDING_TABLE_COVERED_AREA", language), value: "totalAreaOfClosedShelter" },
    { name: i18n.t("BUILDING_TABLE_BUILDINGAREA", language), value: "buildingArea" },
    { name: i18n.t("BUILDING_TABLE_TOTAL_BASEMENT", language), value: "totalBasementArea" },
    { name: i18n.t("BUILDING_TABLE_USED_BASEMENT", language), value: "usedBasementArea" },
    { name: i18n.t("BUILDING_TABLE_DEEP_BASEMENT", language), value: "deepBasementArea" },
    { name: i18n.t("BUILDING_TABLE_ATTIC_TOTAL", language), value: "totalAtticArea" },
    { name: i18n.t("BUILDING_TABLE_ATTIC_USED", language), value: "usedAtticArea" },
    { name: i18n.t("BUILDING_TABLE_TOTAL_OTHER", language), value: "totalOtherArea" },
    { name: i18n.t("BUILDING_TABLE_TOTAL_RESIDENTIAL", language), value: "totalResidentialArea" },
    { name: i18n.t("BUILDING_TABLE_LEGAL_BASEMENT", language), value: "legalBasementResidence" },
    { name: i18n.t("BUILDING_TABLE_TOTAL_COMMERCIAL", language), value: "totalCommercialArea" },
    {
      name: i18n.t("BUILDING_TABLE_BASEMENT_COMMERCIAL", language),
      value: "commercialBasementArea",
    },
    {
      name: i18n.t("BUILDING_TABLE_FLOORS_W_O_BASEMENT_AND_ATTIC", language),
      value: "floorsWithoutBasementAndAttic",
    },
  ];
  return map_to_xlsx_sheet(keys, payload, i18n.t("BUILDING_TABLE_DOWNLOAD_NAME"));
};

export const createUnitsSheet = function (payload, language) {
  const keys = [
    { name: i18n.t("UNITS_TABLE_ADDRESS", language), value: "addressText" },
    { name: i18n.t("UNITS_TABLE_LOCATED", language), value: "matEjerlav" },
    { name: i18n.t("UNITS_TABLE_BUILDING", language), value: "buildingNumber" },
    { name: i18n.t("UNITS_TABLE_USAGE", language), value: "usageText" },
    { name: i18n.t("UNITS_TABLE_BATHROOM", language), value: "numberOfBathrooms" },
    { name: i18n.t("UNITS_TABLE_BATHROOM_CONDITION", language), value: "bathroomConditionText" },
    { name: i18n.t("UNITS_TABLE_TOILET", language), value: "numberOfWaterFlushingToilets" },
    { name: i18n.t("UNITS_TABLE_TOILET_CONDITION", language), value: "toiletConditionText" },
    { name: i18n.t("UNITS_TABLE_KITCHEN", language), value: "kitchenConditionText" },
    { name: i18n.t("UNITS_TABLE_TOTAL_RESIDENTIAL", language), value: "residentialArea" },
    { name: i18n.t("UNITS_TABLE_TOTAL_COMMERCIAL", language), value: "commercialArea" },
    { name: i18n.t("UNITS_TABLE_OPEN_BALCONY", language), value: "openShelterArea" },
    { name: i18n.t("UNITS_TABLE_CLOSED_OUTHOUSE", language), value: "enclosedShelterOuthouseArea" },
    { name: i18n.t("UNITS_TABLE_OTHER_AREA", language), value: "otherArea" },
    { name: i18n.t("UNITS_TABLE_DLR_AREA", language), value: "dlrArea" },
    { name: i18n.t("UNITS_TABLE_NO_OF_ROOMS", language), value: "numberOfRooms" },
    { name: i18n.t("UNITS_TABLE_TOTAL_AREA", language), value: "totalArea" },
    { name: i18n.t("UNITS_TABLE_STATUS", language), value: "statusText" },
  ];
  return map_to_xlsx_sheet(keys, payload, i18n.t("UNIT_TABLE_DOWNLOAD_NAME"));
};

export const createFacilitiesSheet = function (payload, language) {
  const keys = [
    { name: i18n.t("TECH_TABLE_NO", language), value: "facilityNumber" },
    { name: i18n.t("TECH_TABLE_CLASS", language), value: "classificationText" },
    { name: i18n.t("TECH_TABLE_LOCATED", language), value: "matEjerlav" },
    { name: i18n.t("TECH_TABLE_ESTABLISHED", language), value: "constructionYear" },
    { name: i18n.t("TECH_TABLE_CONTENT", language), value: "oilTankContentText" },
    { name: i18n.t("TECH_TABLE_LOCATION", language), value: "locationText" },
    { name: i18n.t("TECH_TABLE_STATUS", language), value: "statusText" },
    { name: i18n.t("TECH_TABLE_MATERIAL", language), value: "oilTankMaterialText" },
    { name: i18n.t("TECH_TABLE_SIZE_CLASS", language), value: "oilTankSizeClassText" },
    { name: i18n.t("TECH_TABLE_SIZE", language), value: "oilTankSize" },
    // { name: i18n.t("TECH_TABLE_TYPE_CODE", language), value: "typeApprovalNr" },
    { name: i18n.t("TECH_TABLE_CONSTRUCT", language), value: "manufacturingYear" },
  ];
  return map_to_xlsx_sheet(keys, payload, i18n.t("TECH_TABLE_DOWNLOAD_NAME"));
};

export const createCondosSheet = function (payload, language) {
  const keys = [
    { name: i18n.t("CONDO_TABLE_ADDRESS", language), value: "addressText" },
    { name: i18n.t("CONDO_TABLE_BFE", language), value: "bfeNumber" },
    { name: i18n.t("CONDO_TABLE_NR", language), value: "condoNumber" },
    // { name: i18n.t("CONDO_TABLE_LOCATED", language), value: "matEjerlav" },
    { name: i18n.t("CONDO_TABLE_USAGE", language), value: "usageText" },
    { name: i18n.t("CONDO_TABLE_OWNER", language), value: "ownersString" },
    { name: i18n.t("CONDO_TABLE_VALUATION", language), value: "valuation" },
    // { name: i18n.t("CONDO_TABLE_SELLING_PRICE", language), value: "latestTransactionPrice" },
  ];
  return map_to_xlsx_sheet(keys, payload, i18n.t("CONDO_TABLE_DOWNLOAD_NAME"));
};

export const createMortgageSheet = function (payload, name, language) {
  const keys = [
    { name: i18n.t("OWNERS_M_DATE", language), value: "landRegistrationDate" },
    { name: i18n.t("OWNERS_M_PRIO", language), value: "priority" },
    { name: i18n.t("OWNERS_M_TYPE", language), value: "chargeType" },
    { name: i18n.t("OWNERS_M_HSTOL", language), value: "amount" },
    { name: i18n.t("OWNERS_M_VALUTA", language), value: "currency" },
    { name: i18n.t("OWNERS_C_INT", language), value: "rate" },
    { name: i18n.t("OWNERS_C_INTTYPE", language), value: "rateType" },
    { name: i18n.t("OWNERS_C_CREDITOR", language), value: "creditorsString" },
    { name: i18n.t("OWNERS_C_DEBITOR", language), value: "debitorsString" },
  ];
  return map_to_xlsx_sheet(keys, payload, name);
};

export const createCondosBuildingsSheet = function (payload, language) {
  const keys = [
    { name: i18n.t("OWNERS_M_TYPE", language), value: "chargeType" },
    { name: i18n.t("OWNERS_M_HSTOL", language), value: "amount" },
    { name: i18n.t("OWNERS_M_VALUTA", language), value: "currency" },
    { name: i18n.t("OWNERS_M_TYPE", language), value: "chargeType" },
    { name: i18n.t("CONDO_ENERGY_SUPPLY", language), value: "energySupplyText" },
    { name: i18n.t("CONDO_TOTAL_AREA", language), value: "totalArea" },
    { name: i18n.t("CONDO_COMMERCIAL_AREA", language), value: "commercialArea" },
    { name: i18n.t("CONDO_RESI_AREA", language), value: "residentialArea" },
    { name: i18n.t("CONDO_OTHER_AREA", language), value: "otherArea" },
    { name: i18n.t("CONDO_COMMON_ACCESS_AREA", language), value: "shareOfCommonAccessAreas" },
    { name: i18n.t("CONDO_OPEN_TERRACE_AREA", language), value: "openShelterArea" },
    { name: i18n.t("CONDO_ENCLOSED_SHELTER", language), value: "enclosedShelterOuthouseArea" },
    { name: i18n.t("CONDO_DLR_AREA", language), value: "dlrArea" },
    { name: i18n.t("CONDO_NO_OF_ROOMS", language), value: "numberOfRooms" },
    { name: i18n.t("CONDO_NO_OF_TOILETS", language), value: "numberOfWaterFlushingToilets" },
    { name: i18n.t("CONDO_NO_OF_BATH", language), value: "numberOfBathrooms" },
    { name: i18n.t("CONDO_KITCH_CONDI", language), value: "kitchenConditionText" },
    { name: i18n.t("CONDO_TOILET_CONDI", language), value: "toiletConditionText" },
    { name: i18n.t("CONDO_REMARKS", language), value: "remarks" },
  ];
  return map_to_xlsx_sheet(keys, payload, i18n.t("CONDO_BUILDING_TABLE_DOWNLOAD_NAME"));
};

export const createTenantsSheet = function (payload, language) {
  const keys = [
    { name: i18n.t("TENANT_LABEL_COMP", language), value: "companyName" },
    { name: i18n.t("TENANT_LABEL_INDUSTRY", language), value: "industry" },
    { name: i18n.t("TENANT_LABEL_EST", language), value: "established" },
    { name: i18n.t("TENANT_LABEL_EQUITY", language), value: "equity" },
    { name: i18n.t("TENANT_LABEL_ADDRESS", language), value: "address" },
    { name: i18n.t("TENANT_LABEL_COMP", language), value: "companyName" },
  ];

  return map_to_xlsx_sheet(keys, payload, i18n.t("TENANT_TABLE_DOWNLOAD_NAME"));
};

export const createEasementsSheet = function (payload, language) {
  const keys = [
    { name: i18n.t("EASEMENT_PRIORITY_TOOLTIP", language), value: "priority" },
    { name: i18n.t("EASEMEMNT_DOCUMENT_LABEL", language), value: "document" },
    { name: i18n.t("DOX_ITEM_ACT_NO", language), value: "file" },
    { name: i18n.t("EASEMEMNT_DATE_ID_LABEL", language), value: "dateId" },
  ];

  return map_to_xlsx_sheet(keys, payload, i18n.t("EASEMENT_TABLE_DOWNLOAD_NAME"));
};

// DOWNLOAD FUNCTIONS

export const createAreasDownload = function (property, condos, language) {
  const plotsSheet = createPlotsSheet(property.plots, language);
  const buildingsSheet = createBuildingsSheet(property.buildings, language);
  const unitsSheet = createUnitsSheet(property.units, language);
  const facilitiesSheet = createFacilitiesSheet(property.technicalFacilities, language);
  const condosSheet = createCondosSheet(condos, language);
  return {
    link: writeSheetsToXSLX([plotsSheet, buildingsSheet, unitsSheet, facilitiesSheet, condosSheet]),
    // name: `${i18n.t("DOX_ITEM_BUILDINGSSHEET", language)}.xlsx`,
    type: "AREAS",
    blob: true,
  };
};

export const createCondoBuildingDownload = function (condo, language) {
  const buildingSheet = createCondosBuildingsSheet(condo, language);
  return {
    link: writeSheetsToXSLX([buildingSheet]),
    type: "AREAS",
    blob: true,
  };
};

export const createBBRSheetDownload = function (bfeNumber, type) {
  return {
    link: `https://nyt.ois.dk/bbrmeddelelse/get?bfe=${bfeNumber}`,
    type: type,
    blob: false,
  };
};

export const createOwnersOverviewDownload = function (docs, condos, language) {
  const keys = [
    { name: i18n.t("OWNERS_OWNER", language), value: "name" },
    { name: i18n.t("OWNERS_OWNERSHIP", language), value: "ownershipType" },
    { name: i18n.t("OWNERS_OWNED", language), value: "theOwned" },
    { name: i18n.t("OWNERS_O_SHARE", language), value: "share" },
  ];

  const motherOwners =
    docs.owners
      ?.filter((x) => x != null)
      .map((x) => {
        x.theOwned = i18n.t("COMPANY_LABEL_TYPE_MAIN_PROPERTY", language);
        x.ownershipType = get_owner_type(x.ownershipCode, language);
        return x;
      }) ?? [];
  const condoOwners = condos?.flatMap((x) =>
    x.owners
      ?.filter((x) => !!x)
      .map((y) => {
        y.theOwned = getAddressAsLongString(x?.unit?.address);
        y.ownershipType = get_owner_type(y?.ownershipCode, language);
        return y;
      })
  );
  const sheet = map_to_xlsx_sheet(keys, [...motherOwners, ...condoOwners], i18n.t("OWNERS_MOTHER", language));
  return {
    link: writeSheetsToXSLX([sheet]),
    type: "OWNERS_SHEET",
    blob: true,
  };
};

export const createMortgagesDownload = function (docs, condos, language) {
  const motherSheet = createMortgageSheet(docs.danishLandRegister?.charges, i18n.t("OWNERS_MOTHER", language), language);
  const unitSheets =
    condos
      ?.filter((x) => x.unit?.danishLandRegister?.charges)
      .map((x) => createMortgageSheet(x.unit.danishLandRegister.charges, x.bfeNumber.toString(), language)) ?? [];
  return {
    link: writeSheetsToXSLX([motherSheet, ...unitSheets]),
    type: "OWNERS_MORTGAGES",
    keys: getMortgatesKeys(docs),
    blob: true,
  };
};

export const createEnergyLabelDownload = function (label, language) {
  // Note Morten: I'm unsure if we sometime get pdfs, but if the link is from sparenergi.dk, we
  // need to change it to get the pdf from emoweb.dk
  if (label.link != null && label.link.includes("sparenergi.dk")) {
    label.link = `https://emoweb.dk/getcompcon/api/Data/GetPDFToBrowser?EnergyLabelSerialIdentifier=${label.id}`;
  }

  return {
    link: label.link,
    type: "ENERGY_LABEL",
    keys: getEnergyLabelKeys(label),
    blob: false,
  };
};

export const createAnnualReportDownload = function (owner, language) {
  return {
    link: owner.company.keyFigures[0].legacyPdf,
    type: "ANNUAL_COMPANY_REPORT",
    keys: getCompanyKeys(owner.company),
    blob: false,
  };
};

export const createLocalPlanDownload = function (plan, language) {
  return {
    link: plan.link,
    type: "LOCAL_PLAN",
    keys: getPlanKeys(plan),
    blob: false,
  };
};

export const createMunicipalityPlanDownload = function (plan, language) {
  return {
    link: plan.link,
    type: "MUNICIPALITY_PLAN",
    keys: getPlanKeys(plan),
    blob: false,
  };
};

export const createSoilConDownload = function (plot, language) {
  return {
    link: `https://jordrapport.miljoeportal.dk/generate?elav=${plot.ownersGuildCode}&matrnr=${plot.matrikelNumber}`,
    type: "SOIL_CON",
    keys: getSoilConKeys(plot),
    blob: false,
  };
};

export const createCorporateDiagramDownload = function (link, owner, language) {
  return {
    link: link,
    type: "OWNERS_DIAGRAM",
    keys: getCompanyKeys(owner.company, language),
    blob: true,
  };
};

export const createListCondosSheet = function (payload, keys) {
  const result = payload.map((prop) => {
    const condo = prop.property?.condo;
    const unit = condo.unit || {};
    const propertyType = "OVERVIEW_TYPE_CONDO";
    const latestTrans = getLatestTransaction(prop.property);
    const latestValue = prop.property?.valuations?.sort((a, b) => new Date(b.valuationDate).getTime() - new Date(a.valuationDate).getTime())[0];

    let obj = {};

    obj.addressText = getAddressAsLongString(unit.address);
    obj.propertyType = i18n.t(propertyType);
    obj.description = prop.description;
    obj.status = prop.status ? prop.status.name : i18n.t("LIST_STATUS_NO_STATUS");
    obj.floorNo = unit.address?.floorNumber;
    obj.constructionYear = getConstructionYears(prop).join(", ");
    obj.noOfRooms = unit.numberOfRooms;
    obj.totalArea = unit.totalArea;
    obj.residentialArea = unit.residentialArea;
    obj.commercialArea = unit.commercialArea;
    obj.weightedArea = calculateWeightedArea(prop.property, areaWeights);
    // obj.inMainProperty = "not feasible"
    obj.publicValuation = latestValue?.propertyValuation;
    obj.latestSalesDate = moment(latestTrans?.handoverDate)?.format("YYYY-MM-DD");
    obj.latestSalesSum = latestTrans?.transactionInfo?.totalPurchaseSum;
    obj.claims = prop.property?.owners
      ?.filter((x) => x != null && x.name != null)
      ?.map((x) => x.name)
      .join(", ");
    obj.constructionYear = condo.unit?.building?.constructionYear;
    obj.extensionYears = condo.unit?.building?.extensionYear;
    obj.publicValuation = latestValue?.propertyValuation;

    return obj;
  });

  return map_to_xlsx_sheet(keys, result, i18n.t("CONDO_TABLE_DOWNLOAD_NAME"));
};

export const createListPropertiesSheet = function (payload, keys) {
  const result = payload.map((prop) => {
    const propertyType = prop.property?.isDividedInCondos ? "OVERVIEW_TYPE_PROPERTY_WITH_CONDOS" : "OVERVIEW_TYPE_MAIN_PROPERTY";
    const latestTrans = getLatestTransaction(prop.property);
    const latestValue = prop.property?.valuations?.sort((a, b) => new Date(b.valuationDate).getTime() - new Date(a.valuationDate).getTime())[0];

    let obj = {};
    let plotWithAddress = prop.property?.plots?.find((p) => p.address)?.address;
    obj.addressText = plotWithAddress ? getAddressAsLongString(plotWithAddress) : i18n.t("VALUE_NOT_REGISTERED");
    obj.matEjerlav = prop?.property?.plots.map((p) => `${p.matrikelNumber}, ${p.ownersGuildName}`).join(",");
    obj.propertyType = i18n.t(propertyType);
    obj.description = prop.description;
    obj.status = prop.status ? prop.status.name : i18n.t("LIST_STATUS_NO_STATUS");
    obj.weightedArea = calculateWeightedArea(prop.property, areaWeights);
    obj.noOfFloors = prop.property?.plots?.reduce((acc, obj) => acc + obj?.buildings?.reduce((acc2, obj2) => acc2 + obj2.numberOfFloors || 0, 0) || 0, 0);
    obj.noOfRooms = prop.property?.plots?.reduce(
      (acc, obj) => acc + obj?.buildings?.reduce((acc2, obj2) => acc2 + obj2.units?.reduce((acc3, obj3) => acc3 + obj3.numberOfRooms || 0, 0) || 0, 0) || 0,
      0
    );
    // obj.residentialArea = "";
    obj.commercialArea = prop.property?.plots?.reduce(
      (acc, obj) => acc + obj?.buildings?.reduce((acc2, obj2) => acc2 + obj2.totalCommercialArea || 0, 0) || 0,
      0
    );
    obj.buildingArea = prop.property?.plots?.reduce((acc, obj) => acc + obj?.buildings?.reduce((acc2, obj2) => acc2 + obj2.buildingArea || 0, 0) || 0, 0);
    obj.siteArea = prop.property?.plots?.reduce((acc, obj) => acc + obj?.registeredArea || 0, 0);
    obj.oldestConstructionYear = prop.property?.plots
      ?.flatMap((p) => p.buildings)
      ?.map((b) => b?.constructionYear)
      .filter((cy) => cy && cy !== 1000)
      .sort((a, b) => a - b)[0];
    obj.allConstructionYears = [
      ...new Set(
        prop.property?.plots
          ?.flatMap((p) => p.buildings)
          ?.map((b) => b?.constructionYear)
          .filter((cy) => cy && cy !== 1000)
      ),
    ]
      .sort((a, b) => b - a)
      .join(", ");
    obj.extensionYears = [
      ...new Set(
        prop.property?.plots
          ?.flatMap((p) => p.buildings)
          ?.map((b) => b?.extensionYear)
          .filter((ey) => ey && ey !== 1000)
      ),
    ]
      .sort((a, b) => b - a)
      .join(", ");
    obj.publicValuation = latestValue?.propertyValuation;
    obj.latestSalesDate = moment(latestTrans?.handoverDate).format("YYYY-MM-DD");
    obj.latestSalesSum = latestTrans?.transactionInfo?.totalPurchaseSum;
    obj.claims = prop.property?.owners
      ?.filter((x) => x != null && x.name != null)
      ?.map((x) => x.name)
      .join(", ");
    return obj;
  });

  return map_to_xlsx_sheet(keys, result, i18n.t("LIST_EXPORT_MAINPROPERTIES"));
};

export const createListCondosSheetCompany = function (payload, keys) {
  const result = payload.map((prop) => {
    const condo = prop?.condo;
    const unit = condo.unit || {};
    const propertyType = "OVERVIEW_TYPE_CONDO";
    const latestTrans = getLatestTransaction(prop);
    const latestValue = [...(prop?.valuations ?? [])].sort((a, b) => new Date(b.valuationDate).getTime() - new Date(a.valuationDate).getTime())[0];

    let obj = {};

    obj.addressText = getAddressAsLongString(unit.address);
    obj.propertyType = i18n.t(propertyType);
    obj.description = prop.description;
    obj.status = prop.status ? prop.status.name : i18n.t("LIST_STATUS_NO_STATUS");
    obj.floorNo = unit.address?.floorNumber;
    obj.constructionYear = getConstructionYears(prop).join(", ");
    obj.noOfRooms = unit.numberOfRooms;
    obj.totalArea = unit.totalArea;
    obj.residentialArea = unit.residentialArea;
    obj.commercialArea = unit.commercialArea;
    obj.weightedArea = calculateWeightedArea(prop, areaWeights);
    // obj.inMainProperty = "not feasible"
    obj.publicValuation = latestValue?.propertyValuation;
    obj.latestSalesDate = moment(latestTrans?.handoverDate)?.format("YYYY-MM-DD");
    obj.latestSalesSum = latestTrans?.transactionInfo?.totalPurchaseSum;
    obj.claims = prop?.owners
      ?.filter((x) => x != null && x.name != null)
      ?.map((x) => x.name)
      .join(", ");
    obj.constructionYear = condo.unit?.building?.constructionYear;
    obj.extensionYears = condo.unit?.building?.extensionYear;
    obj.publicValuation = latestValue?.propertyValuation;

    return obj;
  });

  return map_to_xlsx_sheet(keys, result, i18n.t("CONDO_TABLE_DOWNLOAD_NAME"));
};

export const createListPropertiesSheetCompany = function (payload, keys) {
  const result = payload.map((prop) => {
    const propertyType = prop?.isDividedInCondos ? "OVERVIEW_TYPE_PROPERTY_WITH_CONDOS" : "OVERVIEW_TYPE_MAIN_PROPERTY";
    const latestTrans = getLatestTransaction(prop);
    const latestValue = [...(prop?.valuations ?? [])].sort((a, b) => new Date(b.valuationDate).getTime() - new Date(a.valuationDate).getTime())[0];

    let obj = {};
    let plotWithAddress = prop?.plots?.find((p) => p.address)?.address;
    obj.addressText = plotWithAddress ? getAddressAsLongString(plotWithAddress) : i18n.t("VALUE_NOT_REGISTERED");
    obj.matEjerlav = prop?.plots.map((p) => `${p.matrikelNumber}, ${p.ownersGuildName}`).join(",");
    obj.propertyType = i18n.t(propertyType);
    obj.description = prop.description;
    obj.status = prop.status ? prop.status.name : i18n.t("LIST_STATUS_NO_STATUS");
    obj.weightedArea = calculateWeightedArea(prop, areaWeights);
    obj.noOfFloors = prop?.plots?.reduce((acc, obj) => acc + obj?.buildings?.reduce((acc2, obj2) => acc2 + obj2.numberOfFloors || 0, 0) || 0, 0);
    obj.noOfRooms = prop?.plots?.reduce(
      (acc, obj) => acc + obj?.buildings?.reduce((acc2, obj2) => acc2 + obj2.units?.reduce((acc3, obj3) => acc3 + obj3.numberOfRooms || 0, 0) || 0, 0) || 0,
      0
    );
    // obj.residentialArea = "";
    obj.commercialArea = prop?.plots?.reduce((acc, obj) => acc + obj?.buildings?.reduce((acc2, obj2) => acc2 + obj2.totalCommercialArea || 0, 0) || 0, 0);
    obj.buildingArea = prop?.plots?.reduce((acc, obj) => acc + obj?.buildings?.reduce((acc2, obj2) => acc2 + obj2.buildingArea || 0, 0) || 0, 0);
    obj.siteArea = prop?.plots?.reduce((acc, obj) => acc + obj?.registeredArea || 0, 0);
    obj.oldestConstructionYear = prop?.plots
      ?.flatMap((p) => p.buildings)
      ?.map((b) => b?.constructionYear)
      .filter((cy) => cy && cy !== 1000)
      .sort((a, b) => a - b)[0];
    obj.allConstructionYears = [
      ...new Set(
        prop?.plots
          ?.flatMap((p) => p.buildings)
          ?.map((b) => b?.constructionYear)
          .filter((cy) => cy && cy !== 1000)
      ),
    ]
      .sort((a, b) => b - a)
      .join(", ");
    obj.extensionYears = [
      ...new Set(
        prop?.plots
          ?.flatMap((p) => p.buildings)
          ?.map((b) => b?.extensionYear)
          .filter((ey) => ey && ey !== 1000)
      ),
    ]
      .sort((a, b) => b - a)
      .join(", ");
    obj.publicValuation = latestValue?.propertyValuation;
    obj.latestSalesDate = moment(latestTrans?.handoverDate).format("YYYY-MM-DD");
    obj.latestSalesSum = latestTrans?.transactionInfo?.totalPurchaseSum;
    obj.claims = prop?.owners
      ?.filter((x) => x != null && x.name != null)
      ?.map((x) => x.name)
      .join(", ");
    return obj;
  });

  return map_to_xlsx_sheet(keys, result, i18n.t("LIST_EXPORT_MAINPROPERTIES"));
};

// Note Morten: The following functions are not used but may be useful in the future for mapping
// DLR keys in the frontend

/*export const getMappedKeys = function (keys, language) {
  const mappedKeys = mapKeys(keys, language);

  console.log("mappedKeys", mappedKeys);
  let patterns = [];
  mappedKeys.forEach((d) => {
    let pattern = {
      type: d.type,
      separator: ", ",
      dateFormat: "date",
      download: true,
      words: [],
    };
    d.selectedFields.forEach((f, i) => {
      let word = {
        id: f.text,
        position: i,
        custom: false,
      };
      if (f.type === "custom" || f.type === "standard") word.custom = true;
      if (f.type === "standard") word.id = f.name;
      pattern.words.push(word);
    });
    patterns.push(pattern);
  });
  return patterns;
};

const mapKeys = function (keys, language) {
  // RESET ARRAYS
  let selectedDocsWithFields = [];
  keys.forEach((x) => {
    let element = {
      type: x.type,
      keys: [],
      selectedFields: [],
    };

    x.keys.forEach((y) => {
      let k = {
        text: y.id,
        name: y.text,
        type: "column",
      };
      element.keys.push(k);
    });

    element.keys.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    element.keys.unshift(mapTypeKeys(x.type, language));

    selectPattern(element);

    selectedDocsWithFields.push(element);
  });

  return selectedDocsWithFields;
};

const selectPattern = function (element) {
  selectStandardFields(element.keys, element.selectedFields);
  switch (element.type) {
    case "ATTEST":
      selectField(element.keys, element.selectedFields, "Addressekaldenavn");

      break;
    case "DEED":
      selectField(element.keys, element.selectedFields, "Addressekaldenavn");
      selectField(element.keys, element.selectedFields, "DokumentAliasIdentifikator");

      break;
    case "LIABILITIES":
      selectField(element.keys, element.selectedFields, "PrioritetNummer");
      selectField(element.keys, element.selectedFields, "HaeftelseType");
      selectField(element.keys, element.selectedFields, "ValutaKode");
      selectField(element.keys, element.selectedFields, "BeloebVaerdi");
      selectField(element.keys, element.selectedFields, "DokumentAliasIdentifikator");
      break;
    case "ENDORSEMENT":
      selectField(element.keys, element.selectedFields, "DokumentAliasIdentifikator");

      break;
    case "ACT":
      selectField(element.keys, element.selectedFields, "DokumentFilnavnTekst");
      break;
  }
};
const selectField = function (availableFields, selectedFields, fieldName) {
  let element;

  availableFields.forEach((field) => {
    if (field.text === fieldName) {
      element = field;
    }
  });

  if (element != undefined) {
    let availableIndex = availableFields.indexOf(element);
    if (availableIndex > -1) availableFields.splice(availableIndex, 1);

    selectedFields.push(element);
  }
};
const selectStandardFields = function (availableFields, selectedFields) {
  let name = availableFields[0];
  let availableIndexName = availableFields.indexOf(name);
  if (availableIndexName > -1) availableFields.splice(availableIndexName, 1);

  selectedFields.push(name);
};

const mapTypeKeys = function (type, language) {
  switch (type) {
    case "ATTEST":
      return {
        text: language == null || language === "da" ? "Tingbogsattest" : "Danish land registry certificate",
        name: language == null || language === "da" ? "Tingbogsattest" : "Danish land registry certificate",
        type: "standard",
      };
    case "DEED":
      return {
        text: language == null || language === "da" ? "Skøde" : "Deed of conveyance",
        name: language == null || language === "da" ? "Skøde" : "Deed of conveyance",
        type: "standard",
      };
    case "LIABILITIES":
      return {
        text: language == null || language === "da" ? "Hæftelse" : "Charge",
        name: language == null || language === "da" ? "Hæftelse" : "Charge",
        type: "standard",
      };
    case "ENDORSEMENT":
      return {
        text: language == null || language === "da" ? "Påtegning på hæftelse" : "Endorsement on charge",
        name: language == null || language === "da" ? "Påtegning på hæftelse" : "Endorsement on charge",
        type: "standard",
      };
    case "ACT":
      return {
        text: language == null || language === "da" ? "Akt" : "Act",
        name: language == null || language === "da" ? "Akt" : "Act",
        type: "standard",
      };
  }
};*/
